// import Nav from "./partial/NavCenterLight"
import Footer from "../partial/Footer";
import Settings from "./Settings";
import Display from "./Display";
import Social from "./Social";
import Locations from "./Locations";
import Notifications from "./Notifications";
import { useEffect, useState } from "react";
import { f } from "../utils";
import { useParams } from "react-router-dom";
import Experience from "../Components/Experience";

// import { Link } from "react-router-dom"
// import Switcher from "./partial/Switcher"

function Profile(props) {
  const { user, nav, readOnly } = props;
  const [area, setArea] = useState("display");
  const [profile, setProfile] = useState({});

  let { id } = useParams();

  function reloadProfile() {
    if (readOnly) {
      f(`/profiles/${id}`).then(setProfile);
    } else {
      f("/profile").then(setProfile);
    }
  }

  useEffect(reloadProfile, [id, readOnly]);

  function update(q) {
    f(`/profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(q),
    }).then((data) => {
      setProfile({ ...profile, ...q });
      // alert(JSON.stringify({...profile, ...q}))
    });
  }

  function dp(path) {
    f(`/deletePortfolio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ path }),
    }).then((data) => {
      setProfile({
        ...profile,
        portfolio: profile.portfolio.filter((p) => p !== path),
      });
    });
  }

  // partials/navbar/nav-center-light.html

  return (
    <div>
      {nav()}
      <section className="lg:pb-24 pb-16">
        <div className="container mt-24">
          <div className="flex gap-5 mt-10">
            <div className="w-1/5 mt-24">
              {/* navigations */}
              <div>
                <ul
                  className="list-none sidebar-nav mb-0 mt-3"
                  id="navmenu-nav"
                >
                  <li className="navbar-item account-menu">
                    <button
                      onClick={() => setArea("display")}
                      className="navbar-link text-slate-400 flex items-center py-2 rounded"
                    >
                      <span className="mr-2 text-[18px] mb-0">
                        <i className="uil uil-dashboard" />
                      </span>
                      <h6 className="mb-0 font-semibold">Profile</h6>
                    </button>
                  </li>
                  <li className="navbar-item account-menu">
                    <button
                      onClick={() => setArea("locations")}
                      className="navbar-link text-slate-400 flex items-center py-2 rounded"
                    >
                      <span className="mr-2 text-[18px] mb-0">
                        <i className="uil uil-diary-alt" />
                      </span>
                      <h6 className="mb-0 font-semibold">Locations</h6>
                    </button>
                  </li>
                  <li className="navbar-item account-menu">
                    <button
                      onClick={() => setArea("social")}
                      className="navbar-link text-slate-400 flex items-center py-2 rounded"
                    >
                      <span className="mr-2 text-[18px] mb-0">
                        <i className="uil uil-process" />
                      </span>
                      <h6 className="mb-0 font-semibold">Social Profile</h6>
                    </button>
                  </li>
                  {!readOnly && (
                    <li className="navbar-item account-menu">
                      <button
                        onClick={() => setArea("notifications")}
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="mr-2 text-[18px] mb-0">
                          <i className="uil uil-bell" />
                        </span>
                        <h6 className="mb-0 font-semibold">Notifications</h6>
                      </button>
                    </li>
                  )}
                  {!readOnly && (
                    <li className="navbar-item account-menu">
                      <button
                        onClick={() => setArea("settings")}
                        className="navbar-link text-slate-400 flex items-center py-2 rounded"
                      >
                        <span className="mr-2 text-[18px] mb-0">
                          <i className="uil uil-setting" />
                        </span>
                        <h6 className="mb-0 font-semibold">Settings</h6>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="w-2/3 bg-[#F2F2F2] rounded-2xl mt-28 px-5 pb-5">
              <div>
                {area === "social" && (
                  <Social profile={profile} update={update} />
                )}
                {area === "display" && (
                  <Display
                    readOnly={readOnly}
                    profile={profile}
                    {...{ update }}
                    dp={dp}
                    user={user}
                    reloadProfile={reloadProfile}
                  />
                )}
                {area === "settings" && (
                  <Settings profile={profile} update={update} />
                )}
                {area === "notifications" && <Notifications />}
                {area === "locations" && (
                  <Locations profile={profile} update={update} />
                )}
              </div>
            </div>
            <div className="w-1/3 mt-24">
              <Experience
                experience={profile.experience || []}
                {...{ update }}
              />
            </div>
          </div>
        </div>

        {/*end container*/}
        <div className="container lg:mt-24 mt-16">{/*end grid*/}</div>
        {/*end container*/}
      </section>

      <Footer />
      {/* <Switcher /> */}
    </div>
  );

  // <a href="/" onclick="topFunction()" id="back-to-top" class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 right-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"><i class="uil uil-arrow-up"></i></a>
}

export default Profile;
