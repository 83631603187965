function Notifications(){
  return <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
  <div className="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
    <div className="p-6 border-b border-gray-100 dark:border-gray-700">
      <h5 className="text-lg font-semibold">Account Notifications :</h5>
    </div>
    <div className="p-6">
      <div className="flex justify-between pb-4">
        <h6 className="mb-0 font-medium">When someone mentions me</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue id="noti1" />
          <label className="form-check-label" htmlFor="noti1" />
        </div>
      </div>
      <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
        <h6 className="mb-0 font-medium">When someone follows me</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue defaultChecked id="noti2" />
          <label className="form-check-label" htmlFor="noti2" />
        </div>
      </div>
      <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
        <h6 className="mb-0 font-medium">When shares my activity</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue id="noti3" />
          <label className="form-check-label" htmlFor="noti3" />
        </div>
      </div>
      <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
        <h6 className="mb-0 font-medium">When someone messages me</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue id="noti4" />
          <label className="form-check-label" htmlFor="noti4" />
        </div>
      </div>
    </div>
  </div>
  <div className="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
    <div className="p-6 border-b border-gray-100 dark:border-gray-700">
      <h5 className="text-lg font-semibold">Marketing Notifications :</h5>
    </div>
    <div className="p-6">
      <div className="flex justify-between pb-4">
        <h6 className="mb-0 font-medium">There is a sale or promotion</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue id="noti5" />
          <label className="form-check-label" htmlFor="noti5" />
        </div>
      </div>
      <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
        <h6 className="mb-0 font-medium">Company news</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue id="noti6" />
          <label className="form-check-label" htmlFor="noti6" />
        </div>
      </div>
      <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
        <h6 className="mb-0 font-medium">Weekly jobs</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue defaultChecked id="noti7" />
          <label className="form-check-label" htmlFor="noti7" />
        </div>
      </div>
      <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
        <h6 className="mb-0 font-medium">Unsubscribe News</h6>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" defaultValue defaultChecked id="noti8" />
          <label className="form-check-label" htmlFor="noti8" />
        </div>
      </div>
    </div>
  </div>
</div>

}

export default Notifications