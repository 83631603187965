import { useState } from "react";

function Switcher() {
  let [theme, setTheme] = useState("dark");

  function toggleTheme() {
    let newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);

    if (newTheme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }

  return (
    <div className="fixed top-1/4 -right-1 z-3">
      <span className="relative inline-block rotate-90">
        <input
          type="checkbox"
          onChange={toggleTheme}
          className="checkbox opacity-0 absolute"
          id="chk"
        />
        <label
          className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
          htmlFor="chk"
        >
          <span className="uil uil-moon text-[20px] text-yellow-500">🌙</span>
          <span className="uil uil-sun text-[20px] text-yellow-500">☀︎</span>
          <span className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7" />
        </label>
      </span>
    </div>
  );
}

export default Switcher;
