import { Twitter, Facebook, Instagram, Youtube, Linkedin} from 'react-feather';
// import { f } from "../utils"

function Social(props) {
  const {profile, update} = props

  function save(section) {
    let q = [...document.querySelectorAll(`#${section} [name]`)].reduce((acc, el) => {
      let key = el.getAttribute("name")
      let value = el.value || el.innerText
      acc[key] = value
      return acc
    }, {})
    update(q)
  }

  return <div id="social" className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
    <div className="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
      <div className="p-6 border-b border-gray-100 dark:border-gray-700">
        <h5 className="text-lg font-semibold">Social Profiles :</h5>
      </div>
      <div className="p-6">
        <div className="md:flex">
          <div className="md:w-1/3">
            <span className="font-medium">Twitter</span>
          </div>
          <div className="md:w-2/3 mt-4 md:mt-0">

            <div className="form-icon relative">
              <i data-feather="twitter" className="w-4 h-4 absolute top-2 left-2" ><Twitter /></i>
              <input type="text" className="form-input pl-12" placeholder="Twitter Profile Name" name="twitter" defaultValue={profile?.twitter} />
            </div>

            <p className="text-slate-400 mt-1">Add your Twitter username (e.g. jennyhot).</p>
          </div>
        </div>
        <div className="md:flex mt-8">
          <div className="md:w-1/3">
            <span className="font-medium">Facebook</span>
          </div>
          <div className="md:w-2/3 mt-4 md:mt-0">

            <div className="form-icon relative">
              <i data-feather="facebook" className="w-4 h-4 absolute top-2 left-2"><Facebook/></i>
              <input type="text" className="form-input pl-12" placeholder="Facebook Profile Name" name="facebook" defaultValue={profile?.facebook} />
            </div>

            <p className="text-slate-400 mt-1">Add your Facebook username (e.g. jennyhot).</p>
          </div>
        </div>
        <div className="md:flex mt-8">
          <div className="md:w-1/3">
            <span className="font-medium">Instagram</span>
          </div>
          <div className="md:w-2/3 mt-4 md:mt-0">

            <div className="form-icon relative">
              <i data-feather="instagram" className="w-4 h-4 absolute top-2 left-2"><Instagram/></i>
              <input type="text" className="form-input pl-12" placeholder="Instagram Profile Name" name="instagram"  defaultValue={profile?.instagram}/>
            </div>

            <p className="text-slate-400 mt-1">Add your Instagram username (e.g. jennyhot).</p>
          </div>
        </div>
        <div className="md:flex mt-8">
          <div className="md:w-1/3">
            <span className="font-medium">Linkedin</span>
          </div>
          <div className="md:w-2/3 mt-4 md:mt-0">

            <div className="form-icon relative">
              <i data-feather="linkedin" className="w-4 h-4 absolute top-2 left-2"><Linkedin/></i>
              <input type="text" className="form-input pl-12" placeholder="Linkedin Profile Name" name="linkedin"  defaultValue={profile?.linkedin}/>
            </div>

            <p className="text-slate-400 mt-1">Add your Linkedin username.</p>
          </div>
        </div>
        <div className="md:flex mt-8">
          <div className="md:w-1/3">
            <span className="font-medium">Youtube</span>
          </div>
          <div className="md:w-2/3 mt-4 md:mt-0">

            <div className="form-icon relative">
              <i data-feather="youtube" className="w-4 h-4 absolute top-2 left-2"><Youtube/></i>
              <input type="url" className="form-input pl-12" placeholder="Youtube url" name="youtube"  defaultValue={profile?.youtube}/>
            </div>

            <p className="text-slate-400 mt-1">Add your Youtube url.</p>
          </div>
        </div>
        <div className="md:flex">
          <div className="md:w-1/3">
            <span className="font-medium" />
            <button onClick={() => save("social")} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Save Profile</button>
          </div>
        </div>
      </div>
    </div>
  </div>

}

export default Social