// import React from "react"
// import Uppy from '@uppy/core'
// import { DashboardModal } from '@uppy/react'
// import '@uppy/core/dist/style.css'
// import '@uppy/dashboard/dist/style.css'
// import { useEffect, useState } from 'react'

/* eslint-disable */
import React from "react";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { DashboardModal } from "@uppy/react";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { u } from "../utils";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let { token, path, text, onClose, onSuccess } = props;

    this.state = {
      showInlineDashboard: false,
      open: false,
    };

    let restrictions = {
      maxFileSize: 1000000,
      // maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*"],
    };
    if (path === "/avatar") {
      restrictions.maxNumberOfFiles = 1;
    }

    (this.uppy = new Uppy({
      id: "uppy1",
      autoProceed: true,
      debug: true,
      restrictions,
    })
      // .use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })
      // .use(GoogleDrive, { companionUrl: 'https://companion.uppy.io' })

      .use(XHRUpload, {
        // endpoint: `https://market-api.mtdr.dev${path}`,
        endpoint: u(path),
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .on("complete", (result) => {
        // this.testing = 'success';
        console.log("successful files:", result.successful);
        if (onSuccess && result.successful?.length) {
          onSuccess(result.successful[0].response.body);
        } else {
          // alert(result.successful?.length)
        }

        console.log("failed files:", result.failed);
      })),
      (this.handleModalClick = this.handleModalClick.bind(this));
  }

  componentWillUnmount() {
    this.uppy.close({ reason: "unmount" });
    // this.uppy2.close({ reason: 'unmount' })
  }

  handleModalClick() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { showInlineDashboard } = this.state;
    let { token, path, text, onClose } = this.props;
    return (
      <div>
        {/* <h1>React Examples</h1>

        <h2>Inline Dashboard</h2>
        <label>
          <input
            type="checkbox"
            checked={showInlineDashboard}
            onChange={(event) => {
              this.setState({
                showInlineDashboard: event.target.checked
              })
            }}
          />
          Show Dashboard
        </label> */}
        {/* {showInlineDashboard && (
          <Dashboard
            uppy={this.uppy}
            plugins={['GoogleDrive']}
            metaFields={[
              { id: 'name', name: 'Name', placeholder: 'File name' }
            ]}
          />
        )} */}
        <DashboardModal
          uppy={this.uppy}
          open={this.state.open}
          target={document.body}
          onRequestClose={() => {
            this.setState({ open: false });
            onClose();
          }}
        />
        <button
          onClick={this.handleModalClick}
          className="px-2 py-1 text-sm bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black  rounded-md mt-2"
        >
          {this.state.open ? "" : text || "Upload"}
        </button>
        {/* <h2>Modal Dashboard</h2>
        <div>

        </div>

        <h2>Drag Drop Area</h2>
        <DragDrop
          uppy={this.uppy}
          locale={{
            strings: {
              chooseFile: 'Boop a file',
              orDragDrop: 'or yoink it here'
            }
          }}
        /> */}

        {/* <h2>Progress Bar</h2>
        <ProgressBar
          uppy={this.uppy}
          hideAfterFinish={false}
        />

        <h2>File Input</h2>
        <FileInput
          uppy={this.uppy}
        /> */}
      </div>
    );
  }
}

// function Uploader(props) {
//   let { token, path, text, onClose } = props
//   let [open, setOpen] = useState(false)

//   const uppy = new Uppy({
//     id: path,
//     debug: true,
//     autoProceed: false,
//     restrictions: {
//       maxFileSize: 1000000,
//       // maxNumberOfFiles: 1,
//       allowedFileTypes: ['image/*'],
//     }
//   })

//   useEffect(() => {
//     if (token) {
//       uppy.use(XHRUpload, {
//         // endpoint: `https://market-api.mtdr.dev${path}`,
//         id: `${path}:XHRUpload`,
//         type: `XHRUpload`,
//         endpoint: `http://localhost:3300${path}`,
//         headers: {
//           authorization: `Bearer ${token}`,
//         }
//       })
//     } else {
//       alert("no token")
//     }
//     return () => uppy.close({ reason: 'unmount' })

//     // eslint-disable-next-line
//   }, [token])

//   return open ? <DashboardModal

//   uppy={uppy}
//   open={open}
//   onRequestClose={() => {

//     setOpen(false)
//     // onClose()
//     // document.body.style.overflow = 'visible';

//   }}

// /> : <></>
//   // <>
//   // {/* {JSON.stringify({open})} */}
//   //   <div className={open ? "" : "hidden"}>

//   //   </div>
//   //   <div className={open ? "hidden" : ""}>
//   //     <button onClick={() => setOpen(true)} className="px-2 py-1 text-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2">{text || "Upload"}</button>
//   //   </div>
//   // </>

// }

// export default Uploader
