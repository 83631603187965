// import { titleCase } from "title-case";
// ScrollToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router";
import md5 from "crypto-js/md5";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

const theme = document.body.classList.contains("light") ? "light" : "dark";
const bgColor = theme === "dark" ? "black" : "white";
const color = theme === "dark" ? "white" : "black";

const abbreviate = (n) => {
  n = Number(n || 0);
  if (n < 1e3) return n.toFixed(2).replace(/[.0]+$/, "");
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const currency = formatter.format;

const defaultImage = "https://via.placeholder.com/50/090F19?text=None";

function percentChanged(n1, n2) {
  if (isNaN(n1) || isNaN(n2) || !n1) return "";
  let diff = n2 - n1;
  // Take the difference between the starting value and final value.
  let x = diff / n1;
  // return JSON.stringify({n1,n2})
  let v = Math.round(x * 100);
  if (v === 0) return "";
  return `${x >= 0 ? "+" : ""}${v}%`;
  // Divide by the absolute value of the starting value. Multiply the result by 100.
}

let unique = (el, i, self) => i === self.indexOf(el);

const root = window.location.href.match("localhost")
  ? `http://localhost:3300`
  : `https://market-api.mtdr.dev`;
// const root = `https://market-api.mtdr.dev`

function f(url, opts = {}, ...args) {
  let user = JSON.parse(localStorage.getItem("user") || "{}");
  if (user?.auth_token) {
    opts.headers = opts.headers || {};
    opts.headers["Authorization"] = `Bearer ${user.auth_token}`;
  }
  return fetch(`${root}${url}`, opts, ...args).then((r) => r.json());
}

let u = (path) => new URL(path, root).href;

function p(url, body) {
  return f(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
}

let categories = [
  "Yoga",
  "Barre",
  "HIIT",
  "Bootcamp",
  "Martial arts",
  "Rowing",
  "Running",
  "Cycling",
  "Pilates",
  "Dance",
  "Boxing",
  "Outdoors",
  "Gym time",
  "Sports",
  "Sports recovery",
  "Acupuncture",
];

function gravatar(email) {
  let hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}`;
}

export {
  theme,
  color,
  bgColor,
  abbreviate,
  currency,
  defaultImage,
  ScrollToTop,
  percentChanged,
  unique,
  f,
  u,
  p,
  gravatar,
  categories,
};
