import { useState } from "react"
import {categories} from "./utils"
import { Search, MapPin, Check} from 'react-feather';
import { Link, useNavigate, useParams } from "react-router-dom";
import { f } from "./utils"

function SearchOptions(props) {
  let {listings} = props
  let [suggestions, setSuggestions] = useState()

  const navigate = useNavigate();

  const {"*": splat} = useParams ()
  let [lat, lng, tag, dist] = splat.split('/')
  tag = tag || "all"
  dist = dist || "20"

  function loadSuggestions(str){
    if(str.length > 2){
      // let lng = -95.358421
      // let lat = 29.749907
      // fetch(`https://api.locationiq.com/v1/autocomplete?q=${str}&autocomplete=1&viewbox=${lng}%2C${lat}%2C${lng}%2C${lat}&key=pk.3cc4c3f177827ba43696e8a124e9df3a&format=json&source=v1&dedupe=1`).then(r => r.json()).then(setSuggestions)
      f(`/autocomplete/${str}/${lat}/${lng}`).then(setSuggestions)
    }
  }

/*
https://maps.googleapis.com/maps/api/place/autocomplete/json?input=wilk&location=37.76999%2C-122.44696&country=US&radius=500$types=geocode&key=AIzaSyDIm-dxoD9Q_y31Ps6Dpvrxy8pCa7PF-sQ
https://maps.googleapis.com/maps/api/place/details/json?fields=place_id%2Cname%2Crating%2Cformatted_phone_number%2Cgeometry&place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&key=AIzaSyDIm-dxoD9Q_y31Ps6Dpvrxy8pCa7PF-sQ
*/

  // useEffect(() => {
  //   console.log({category, distance})
  // }, [category, distance, location])

  return <div className="p-8">
    {/* {JSON.stringify({tag, dist, splat, suggestions})} */}

    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-2">
      {/* <div className="">
        <i className="uil uil-search icons mt-2 ml-1 absolute" ><Search /></i>
        <input onChange={(e) => alert(e.target.value)}  name="name" type="text" className="pl-8 form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="keywords" />
      </div> */}
      <div className="">
        <i className="uil uil-search icons mt-2 ml-1 absolute" ><MapPin /></i>
        {/* ;setLocation(e.target.value) */}
        <input onChange={(e) => loadSuggestions(e.target.value)} type="text" className="pl-8 form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="location" />
        {suggestions && <div onMouseLeave={() => setSuggestions([])} className="cursor-pointer z-10 absolute bg-yellow-900">
          {/* {suggestions?.filter(s => s.address.city).map((s, i) => <div onClick={() => navigate(`/map/${s.lat}/${s.lon}/${tag}/${dist}`)} key={i} className="p-2 text-sm text-white"> */}
          {suggestions.map((s, i) => <div onClick={() => navigate(`/map/${s.lat}/${s.lng}/${tag}/${dist}`)} key={i} className="p-2 text-sm text-white">
            <p>{s.description}</p>
            <p>{s.city}, {s.state}</p>
          </div>)}
        </div>}
      </div>
      <div className="">
        <i className="uil uil-estate icons mt-2 ml-1 absolute" ><Search/></i>
        <select onChange={(e) => navigate(`/map/${lat}/${lng}/${e.target.value}/${dist}`)} className="form-select z-2 pl-8 w-full" >
          <option value="all">All Categories</option>
          {categories.map((category, i) => <option key={i}>{category}</option>)}
        </select>
      </div>
      <div className="">
        <i className="uil uil-usd-circle icons mt-2 ml-1 absolute" ><Check/></i>
        <select onChange={(e) => navigate(`/map/${lat}/${lng}/${tag}/${e.target.value}`)} className="pl-8 w-full">
          <option value={10}>10 Miles</option>
          <option value={25}>25 Miles</option>
          <option value={50}>50 Miles</option>
          <option value={100}>100 Miles</option>
        </select>
      </div>
    </div>
    <div>

      <div className="h-screen overflow-y-scroll mt-2">
        {listings && listings.map((l, i) => <div key={i} className="grid grid-cols-1 lg:grid-cols-2 gap 1">
          <div className="grid grid-cols-2 gap 1">
            <div className="p-2">
              <Link to={`/trainers/${l._id}`}>
                <img className="w-48 h-36 rounded" src="https://classpass-res.cloudinary.com/image/upload/q_auto,w_320,c_scale,h_160,f_auto/mdbepkjntlg5sususizi.png" alt="gym"></img>
              </Link>
            </div>
            <div className="m-2">
              <p className="leading-tight uppercase font-bold text-slate-700 text-sm">{l.tags.join(", ")}</p>
              <p className="leading-tight">{l.firstname} {l.lastname}</p>
              <p className="leading-tight ">{l.mainLocation?.address}</p>
              <p className="leading-tight flex">
                <span className="font-semibold">{l.rating}</span>
                <span  className="w-4 h-4 mt-[2px] mx-1">
                <svg class="svg-icon" viewBox="0 0 20 20">
							    <path d="M17.684,7.925l-5.131-0.67L10.329,2.57c-0.131-0.275-0.527-0.275-0.658,0L7.447,7.255l-5.131,0.67C2.014,7.964,1.892,8.333,2.113,8.54l3.76,3.568L4.924,17.21c-0.056,0.297,0.261,0.525,0.533,0.379L10,15.109l4.543,2.479c0.273,0.153,0.587-0.089,0.533-0.379l-0.949-5.103l3.76-3.568C18.108,8.333,17.986,7.964,17.684,7.925 M13.481,11.723c-0.089,0.083-0.129,0.205-0.105,0.324l0.848,4.547l-4.047-2.208c-0.055-0.03-0.116-0.045-0.176-0.045s-0.122,0.015-0.176,0.045l-4.047,2.208l0.847-4.547c0.023-0.119-0.016-0.241-0.105-0.324L3.162,8.54L7.74,7.941c0.124-0.016,0.229-0.093,0.282-0.203L10,3.568l1.978,4.17c0.053,0.11,0.158,0.187,0.282,0.203l4.578,0.598L13.481,11.723z"></path>
    						</svg>
                </span>
                (7500+)
              </p>

            </div>
          </div>
          <div className="border-l m-2 px-2 overflow-hidden h-32">
          {l.description}

          </div>


        </div>)}

      </div>





      <pre>{JSON.stringify(listings, null, 2)}</pre>
    </div>

  </div>

}

export default SearchOptions