import { Link } from "react-router-dom";

function Nav(props) {
  const { user, logout } = props;

  return (
    <nav
      id="topnav"
      className="defaultscroll is-sticky bg-white bg-opacity-30 backdrop-blur-xl text-black"
    >
      <div className="container">
        {/* Logo container*/}
        <Link to="/" className="logo pl-0">
          <div className="mt-5">
            <img src="/logo.png" className="w-24" alt="logo"/>
          </div>
        </Link>
        {/* End Logo container*/}
        <div className="menu-extras text-black">
          <div className="menu-item text-black">
            {/* Mobile menu toggle*/}
            <button className="navbar-toggle">
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </button>
            {/* End mobile menu toggle*/}
          </div>
        </div>
        {/*Login button Start*/}
        <ul className="buy-button list-none mb-0 ">
          <li className="inline mb-0">
            <>
              {user?.email ? (
                <Link
                  onClick={logout}
                  className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md "
                >
                  Log out
                </Link>
              ) : (
                <Link
                  to="/login"
                  className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md "
                >
                  Log in
                </Link>
              )}
            </>
          </li>
        </ul>
        {/*Login button End*/}
        <div id="navigation" className="text-black">
          {/* Navigation Menu*/}
          <ul className="navigation-menu">
            <li>
              <Link to="/" className="sub-menu-item">
                Home
              </Link>
            </li>
            <li>
              <Link to="/profile" className="sub-menu-item">
                Profile
              </Link>
            </li>
            <li>
              <Link to="/map" className="sub-menu-item">
                Map
              </Link>
            </li>
            <li>
              <Link to="/contracts" className="sub-menu-item">
                Contracts
              </Link>
            </li>
            {/* <li>
              <Link to="/test" className="sub-menu-item">
                Test
              </Link>
            </li> */}
            {/* <li>
              <Link to="/outlier" className="sub-menu-item">
                Outlier
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
