import { MapContainer, TileLayer, useMapEvents, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import { useEffect, useState } from 'react'
import {f} from "./utils"

import L from 'leaflet';
// import { useNavigate } from 'react-router-dom';

const icon = new L.Icon({
    iconUrl: require('./img/marker-icon.png'),
    iconRetinaUrl: require('./img/marker-icon.png'),
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76],
    shadowSize: [68, 95],
    shadowAnchor: [22, 94],
    iconSize: new L.Point(15, 25),
    className: 'leaflet-div-icon'
});


function LocationMarker(props) {
  let {setProfileId} = props
  let [markers, setMarkers] = useState([])
// let navigate = useNavigate()
  // const [position, setPosition] = useState(null)
  const map = useMapEvents({
    dragend(e) {
      // let {lat, lng} = map.getCenter()
      // console.log({e})
      // navigate(`/map/${lat}/${lng}/${tag}/${dist}`)
      updateMarkers()
      // console.log(e)
      // alert(JSON.stringify({lat,lng}))
      // f(`/markers/${lng}/${lat}`).then(setMarkers)

    },
    locationfound(e) {
      // let {lat, lng} = e.latlng
      // console.log(e)
      // // alert(JSON.stringify(e.latlng))
      // f(`/markers/${lng}/${lat}`).then(setMarkers)
      // setPosition(e.latlng)
      updateMarkers()

      // map.flyTo(e.latlng, map.getZoom())
    },
  })

  function updateMarkers(){
    let {lat, lng} = map.getCenter()
    f(`/markers/${lng}/${lat}`).then(setMarkers)
  }

  // do this first
  useEffect(() => {
    updateMarkers()
    // map.locate()
    // eslint-disable-next-line
  }, [])

  return <>
    {markers.map((marker, i) => {
      let { coords, text, _id } = marker
      return <Marker key={i} position={coords} icon={icon}>
        <Popup><p className="cursor-pointer" onClick={() => setProfileId(_id)} dangerouslySetInnerHTML={{__html: text}}/></Popup>
      </Marker>
    })}
  </>
}

function Leaflet(props) {
  let {center} = props
  let {lat, lng} = center

  function setProfileId(p){
    alert(p)
  }

  return <div className="min-h-screen w-full">
    {/* {JSON.stringify(center)} */}
    <MapContainer center={[lat, lng]} zoom={10} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker {...{setProfileId}}/>
    </MapContainer>
  </div>

}

export default Leaflet