import React, { useState } from "react";
import "feather-icons";
import {
  // useNavigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ScrollToTop } from "./utils";

import Map from "./Map";
import Home from "./Home";
import Profile from "./Profile";
import ReadOnly from "./Profile/ReadOnly";
import Brand from "./Brand";
import Contracts from "./Contracts";
import Blog from "./Blog";
import Test from "./Test";
import Outlier from "./Outlier";

import { Login, Register, ForgotPassword } from "./auth";
import NavCenterLight from "./partial/NavCenterLight";
import "./App.css";

// import { useLocation } from 'react-router-dom';

// custom hook to get the current pathname in React

let oldUser = JSON.parse(localStorage.getItem("user") || "{}");

function Parts() {
  let [user, setUser2] = useState(oldUser);
  // const navigate = useNavigate()

  const setUser = (user) => {
    let { email, auth_token } = user;
    if (user.email) {
      postMessage({ action: "login", email, auth_token }, "*");
    } else {
      postMessage({ action: "logout" }, "*");
    }
    setUser2(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logout = () => {
    setUser({});
    // navigate("/login")
  };

  // console.log(user,setUser)

  let nav = () => <NavCenterLight user={user} logout={logout} />;

  return (
    <div>
      <ScrollToTop>
        <Routes>
          <Route
            path="/profile"
            element={
              user?.email ? (
                user.type === "brand" ? <Brand nav={nav} user={user} /> : <Profile nav={nav} user={user} />
              ) : (
                <Login setUser={setUser} />
              )
            }
          />
          <Route
            path="/trainers/:id"
            element={<ReadOnly nav={nav} readOnly={true} />}
          />
          {/* <Route path="/map" element={<Map/>} /> */}
          <Route path="/map/*" element={<Map />} />

          <Route path="/contracts" element={user?.email ? <Contracts nav={nav}/> : <Login setUser={setUser} />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/test" element={<Test />} />
          <Route path="/outlier" element={<Outlier />} />

          <Route path="/login" element={<Login setUser={setUser} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen">
        <Parts />
      </div>
    </Router>
  );
}

export default App;
