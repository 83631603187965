import Uploader from "../Components/Uploader";
import { u, gravatar } from "../utils";

function Display(props) {
  let { profile, dp, user, reloadProfile, update, readOnly } = props;
  return (
    <div>
      <div className="profile-pic text-center mb-5">
        <div>
          <div className="relative mx-auto h-48 w-48 -mt-24">
            <img
              src={
                profile?.avatar
                  ? u(profile?.avatar)
                  : gravatar(user?.email || "")
              }
              className="rounded-full h-48 w-48 object-cover shadow dark:shadow-gray-800 ring-8 ring-white dark:ring-slate-800"
              id="profile-image"
              alt="nothing"
            />
            <label
              className="absolute inset-0 cursor-pointer"
              htmlFor="pro-img"
            />
          </div>
          <div className="mt-8">
            <h5 className="text-2xl font-semibold">
              {profile?.firstname || "First"} {profile?.lastname || "Last"}
            </h5>
            <div className="flex justify-center">
              <p className="text-slate-400">
                {profile?.email} {profile?.phone && "|"} {profile.phone} |
                Beijing, China {profile?.website && "|"}
                {profile?.website && (
                  <a
                    href={profile.website}
                    target="_blank"
                    rel="noreferrer"
                    className="text-slate-400"
                  >
                    {profile.website.replace(/https?:\/\//i, "")}
                  </a>
                )}
              </p>
            </div>
            <div className="text-slate-400">
              {(profile?.tags || []).join(", ")}
            </div>
          </div>
          {!readOnly && (
            <Uploader
              token={user.auth_token}
              path="/avatar"
              text="Change Avatar"
              onClose={reloadProfile}
            />
          )}
        </div>
      </div>

      <div className="pb-6 border-b border-gray-200 dark:border-gray-700">
        <p className="text-md font-medium mt-3">
          {profile?.description || "Description goes here"}
        </p>
      </div>
      {profile.portfolio && (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-6 gap-3">
          {profile.portfolio.map((path, i) => (
            <div
              key={i}
              className="group h-44 relative block overflow-hidden rounded-md transition-all duration-500"
            >
              <img
                src={u(path)}
                className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                alt="nothing"
              />
              <div className="absolute inset-2 group-hover:bg-white/90 dark:group-hover:bg-slate-900/90 transition duration-500 z-0 rounded-md" />
              <div className="content transition-all duration-500">
                <div className="icon absolute z-10 hidden group-hover:block top-5 right-5 transition-all duration-500">
                  <button
                    onClick={() => dp(path)}
                    className="btn bg-[indigo-600] hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white btn-icon btn-sm lightbox"
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!readOnly && (
        <div className="mt-10">
          <Uploader
            token={user.auth_token}
            path="/portfolio"
            text="+ Add new"
            onClose={reloadProfile}
          />
        </div>
      )}
    </div>
  );
}

export default Display;
