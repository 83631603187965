import { useState } from "react"

function Locations(props) {
  const {profile, update} = props
  let [editing, setEditing] = useState()

  let mainLocation = profile?.mainLocation || {
    address: `C/54 Northwest Freeway, Suite 558,\nHouston, USA 485`,
    phone: `+123 897 5468`
  }
  let { address, phone } = mainLocation

  function save(section){
    let q = [...document.querySelectorAll(`#${section} [name]`)].reduce((acc, el) => {
      let key = el.getAttribute("name")
      let value = el.value || el.innerText
      acc[key] = value
      return acc
    }, {})
    update({mainLocation: q})
    setEditing(false)
  }

  return <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
    <div id="location" className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
      <h6 className="text-slate-400 mb-0">The following addresses will be shown on the map.</h6>
      <div className="md:flex mt-6">
        <div className="md:w-1/2 md:px-3">
          <div className="flex items-center mb-4 justify-between">
            <h5 className="text-xl font-semibold">Main Location {!editing && <button onClick={() => setEditing(true)} className="px-2 py-1 text-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2">edit</button>}</h5>
            <div className="text-indigo-600 text-lg"><i className="uil uil-edit align-middle" /></div>
          </div>
          <div className="pt-4 border-t border-gray-100 dark:border-gray-700">
            <ul className="list-none">
              <li className="flex">
                <i className="uil uil-map-marker text-lg mr-2" />
                {editing ? <textarea name="address" className="w-96">{address}</textarea> : <p className="text-slate-400">{address}</p>}
              </li>
              <li className="flex mt-1">
                <i className="uil uil-phone text-lg mr-2" />
                {editing ? <input name="phone" type="text" className="w-96" defaultValue={phone}/> : <p className="text-slate-400">{phone}</p>}
              </li>
            </ul>
          </div>
          {editing && <button onClick={() => save('location')} className="px-2 py-1 text-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2">Save</button>}
        </div>

        {/* https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDIm-dxoD9Q_y31Ps6Dpvrxy8pCa7PF-sQ */}

        {/* <div className="md:w-1/2 md:px-3 mt-[30] md:mt-0">
          <div className="flex items-center mb-4 justify-between">
            <h5 className="text-xl font-semibold">Other Location</h5>
            <div className="text-indigo-600 text-lg"><i className="uil uil-edit align-middle" /></div>
          </div>
          <div className="pt-4 border-t border-gray-100 dark:border-gray-700">
            <ul className="list-none">
              <li className="flex">
                <i className="uil uil-map-marker text-lg mr-2" />
                <p className="text-slate-400">C/54 Northwest Freeway, Suite 558, <br /> Houston, USA 485</p>
              </li>
              <li className="flex mt-1">
                <i className="uil uil-phone text-lg mr-2" />
                <p className="text-slate-400">+123 897 5468</p>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  </div>


}

export default Locations