import { useState } from "react"
import Tags from "../Components/Tags"

function Settings(props) {
  const {profile, update} = props
  const [tags, setTags] = useState(profile?.tags || [])

  function save(section){
    let q = [...document.querySelectorAll(`#${section} [name]`)].reduce((acc, el) => {
      let key = el.getAttribute("name")
      let value = el.value || el.innerText
      acc[key] = value
      return acc
    }, {})
    if(section === "basic") {
      q.tags = tags
    }
    update(q)
  }

  return <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
    <div id="basic" className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
      <h5 className="text-lg font-semibold mb-4">Personal Detail :</h5>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
        <div>
          <label className="form-label font-medium">First Name : <span className="text-red-600">*</span></label>
          <div className="form-icon relative mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
            <input type="text" className="form-input pl-12" placeholder="First Name:" name="firstname" defaultValue={profile?.firstname} />
            <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></div>
        </div>
        <div>
          <label className="form-label font-medium">Last Name : <span className="text-red-600">*</span></label>
          <div className="form-icon relative mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-check w-4 h-4 absolute top-3 left-4"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><polyline points="17 11 19 13 23 9" /></svg>
            <input type="text" className="form-input pl-12" placeholder="Last Name:" name="lastname"  defaultValue={profile?.lastname} />
          </div>
        </div>
        {/* <div>
          <label className="form-label font-medium">Your Email : <span className="text-red-600">*</span></label>
          <div className="form-icon relative mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
            <input type="email" className="form-input pl-12" placeholder="Email" name="email" required />
          </div>
        </div> */}
        <div>
          <label className="form-label font-medium">Tags : </label>
          {/* {JSON.stringify(tags)} */}
          <div className="form-icon relative mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark w-4 h-4 absolute top-3 left-4"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" /></svg>
            <Tags update={setTags} init={profile?.tags || []} />
          </div>
        </div>
      </div>{/*end grid*/}
      <div className="grid grid-cols-1">
        <div className="mt-5">
          <label className="form-label font-medium">Description : </label>
          <div className="form-icon relative mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-circle w-4 h-4 absolute top-3 left-4"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" /></svg>
            <textarea name="description" className="form-input pl-11 h-28">{profile?.description}</textarea>
          </div>
        </div>
      </div>{/*end row*/}
      <button onClick={() => save("basic")} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" >Save Changes</button>

    </div>
    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
        <div id="contact">
          <h5 className="text-lg font-semibold mb-4">Contact Info :</h5>

          <div className="grid grid-cols-1 gap-5">
            <div>
              <label className="form-label font-medium">Phone No. :</label>
              <div className="form-icon relative mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone w-4 h-4 absolute top-3 left-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                <input name="phone" type="phone" className="form-input pl-12" placeholder="(xxx-xxx-xxxx)" defaultValue={profile?.phone} />
              </div>
            </div>
            <div>
              <label className="form-label font-medium">Website :</label>
              <div className="form-icon relative mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-globe w-4 h-4 absolute top-3 left-4"><circle cx={12} cy={12} r={10} /><line x1={2} y1={12} x2={22} y2={12} /><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" /></svg>
                <input name="website" type="url" className="form-input pl-12" placeholder="https://..." defaultValue={profile?.website}/>
              </div>
            </div>
          </div>{/*end grid*/}
          <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Add</button>
          <button onClick={() => save("contact")} className="ml-3 btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Save</button>

        </div>{/*end col*/}
        <div>
          <h5 className="text-lg font-semibold mb-4">Change password :</h5>

          <div className="grid grid-cols-1 gap-5">
            <div>
              <label className="form-label font-medium">Old password :</label>
              <div className="form-icon relative mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-key w-4 h-4 absolute top-3 left-4"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg>
                <input type="password" className="form-input pl-12" placeholder="Old password" required />
                <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></div>
            </div>
            <div>
              <label className="form-label font-medium">New password :</label>
              <div className="form-icon relative mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-key w-4 h-4 absolute top-3 left-4"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg>
                <input type="password" className="form-input pl-12" placeholder="New password" required />
                <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></div>
            </div>
            <div>
              <label className="form-label font-medium">Re-type New password :</label>
              <div className="form-icon relative mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-key w-4 h-4 absolute top-3 left-4"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg>
                <input type="password" className="form-input pl-12" placeholder="Re-type New password" required />
                <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></div>
            </div>
          </div>{/*end grid*/}
          <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Save password</button>

        </div>{/*end col*/}
      </div>{/*end row*/}
    </div>
  </div>

}

export default Settings