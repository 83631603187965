import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import uuid from 'react-uuid'
import Uploader from "./Components/Uploader";
import { u, f, p, gravatar } from "./utils";

function Brand(props) {
  const { user, nav } = props;
  let [editing, setEditing] = useState(-1)
  let [contracts, setContracts] = useState([])
  let [applications, setApplications] = useState([])
  let [tab, setTab] = useState("contracts")

  useEffect(() => {
    f('/brand/contracts').then(setContracts)
    f('/brand/applications').then(setApplications)
  }, [user])

  // {
  //   logo: "https://res.cloudinary.com/tailwind-labs/image/upload/h_120,w_120,c_fill/v1663182380/tailwind_jobs_company_logos/efsvwavltvdytzyxe2gg.png",
  //   company: "Apple",
  //   location: "Anywhere",
  //   title: "Trainer",
  //   types: ["Full-time", "Contract"],
  //   commission: "$25",
  // },
  // {
  //   logo: "https://res.cloudinary.com/tailwind-labs/image/upload/h_120,w_120,c_fill/v1663182380/tailwind_jobs_company_logos/efsvwavltvdytzyxe2gg.png",
  //   company: "Apple",
  //   location: "Anywhere",
  //   title: "Trainer",
  //   types: ["Full-time", "Contract"],
  //   commission: "$25",
  // }

  function save() {
    var form = document.querySelector('#editing')
    var data = Object.fromEntries(new FormData(form))
    data.types = []
    if (data["Full Time"]) data.types.push("Full Time")
    if (data["Part Time"]) data.types.push("Part Time")
    if (data["Contract"]) data.types.push("Contract")

    delete data["Full Time"]
    delete data["Part Time"]
    delete data["Contract"]

    let newContracts = [...contracts]
    newContracts[editing] = data
    p('/brand/contracts', newContracts)//.then(setContracts)
    setContracts(newContracts)
    setEditing(-1)
    // alert(JSON.stringify(data))
  }

  function newContract() {
    let contract = {
      logo: u('/logos/default.png'),
      company: "Company Name",
      location: "Location",
      title: "Title",
      types: [],
      id: uuid(),
      commission: ""
    }

    p('/brand/contracts', [...contracts, contract]).then(setContracts)
  }

  function remove(n) {
    if (window.confirm("Delete this contract?")) {
      let newContracts = [...contracts.slice(0, n), ...contracts.slice(n + 1)]
      p('/brand/contracts', newContracts)
      setContracts(newContracts)
    }
  }

  let actionRef = useRef()
  function doAction() {
    let action = actionRef.current.value
    let ids = [...document.querySelectorAll('input')].map(c => c.checked ? c.value : "").filter(x => x)

    p('/contracts/statuses', { action, ids }).then(() => {
      f('/brand/applications').then(setApplications)
    })
    // let newStatuses = {...statuses}
    // for(let id of ids){
    //   newStatuses[id] = action
    // }
    // setStatuses(newStatuses)
  }

  return <div>
    {nav()}
    <main className="relative pt-10 pb-16 sm:pb-10 sm:pt-20 lg:pb-28">

      <div className="tabs container m-auto">
        <button onClick={() => setTab("contracts")} className={`tab tab-lg tab-lifted ${tab === "contracts" ? "tab-active" : ""}`}>Contracts</button>
        <button onClick={() => setTab("applications")} className={`tab tab-lg tab-lifted ${tab === "applications" ? "tab-active" : ""}`}>Applications</button>
      </div>
      <div className={tab === "contracts" ? "" : "hidden"}>
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="mx-auto max-w-[40rem] text-4xl font-extrabold tracking-tight text-gray-900 sm:text-center sm:text-5xl sm:leading-[3.5rem] lg:max-w-4xl">
            Your Contracts
          </h1>
          <p className="mx-auto mt-4 max-w-[40rem] text-lg text-gray-600 sm:text-center lg:max-w-xl">
            These are your currently active contracts
          </p>
        </div>
        <div className="mt-4">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-[37rem] lg:max-w-[50rem]">
              <ul className="divide-y divide-gray-100">
                {contracts.map((contract, i) => {
                  let { logo, company, location, title, types, commission, id } = contract
                  return <li key={i}>
                    <div className="group relative py-6 sm:rounded-2xl hover:bg-gray-100">
                      <div className="flex float-right">
                        <button className="z-10 p-1 bg-gray-100 hover:bg-gray-200 rounded-full mx-1" onClick={() => remove(i)} title="delete">
                          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                          </svg>
                        </button>
                        {editing !== i && <button className="p-1 bg-gray-100 hover:bg-gray-200 rounded-full  mx-1" onClick={() => setEditing(i)} title="edit">
                          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                          </svg>
                        </button>}
                      </div>

                      {/* <div className="absolute -inset-x-4 -inset-y-px bg-gray-50 opacity-0 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl lg:-inset-x-8" >

                    </div> */}

                      {editing === i ? <div className="relative items-center px-4 space-y-4">

                        <form id="editing" onSubmit={e => e.preventDefault()}>
                          <div className="relative h-[3.125rem] w-[3.125rem] sm:h-[3.75rem] sm:w-[3.75rem] flex-none">
                            <img
                              id="logo"
                              className="absolute inset-0 h-full w-full rounded-full object-cover"
                              src={logo}
                              alt={title}
                            />
                            <input type="hidden" name="logo" value={logo} />
                            <input type="hidden" name="id" value={id} />
                            <div className="absolute inset-0 rounded-full ring-1 ring-inset ring-black/[0.08]" />
                          </div>

                          <Uploader
                            token={user.auth_token}
                            path="/logo"
                            text="Change Logo"
                            onClose={(x) => { }}
                            onSuccess={(x) => {
                              // console.log(x)
                              // console.log(x.url)
                              // console.log(u(x.url))
                              document.querySelector('[name="logo"]').value = u(x.url)
                              document.querySelector('#logo').src = u(x.url)
                            }}
                          />

                          <div>
                            <label className="block mb-2 text-sm font-medium">Company</label>
                            <div className="relative">
                              <input type="text" name="company" defaultValue={company} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                          </div>

                          <div>
                            <label className="block mb-2 text-sm font-medium">Location</label>
                            <div className="relative">
                              <input type="text" name="location" defaultValue={location} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                          </div>

                          <div>
                            <label className="block mb-2 text-sm font-medium">Title</label>
                            <div className="relative">
                              <input type="text" name="title" defaultValue={title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                          </div>

                          <div>
                            <label className="block mb-2 text-sm font-medium">Commission</label>
                            <div className="relative">
                              <input type="text" name="commission" defaultValue={commission} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                          </div>

                          <div className="space-y-2 mt-2">
                            <div className="flex items-center">
                              <input name="Full Time" type="checkbox" defaultChecked={types.includes("Full Time")} className="w-4 h-4" />
                              <label htmlFor="checkbox-1" className="ml-2 text-sm font-medium">Full Time</label>
                            </div>
                            <div className="flex items-center">
                              <input name="Part Time" type="checkbox" defaultChecked={types.includes("Part Time")} className="w-4 h-4" />
                              <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium">Part Time</label>
                            </div>
                            <div className="flex items-center">
                              <input name="Contract" type="checkbox" defaultChecked={types.includes("Contract")} className="w-4 h-4" />
                              <label htmlFor="contract" className="ml-2 text-sm font-medium">Contract</label>
                            </div>
                          </div>

                          <div className="text-center"><button onClick={save} className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md">Save</button></div>
                        </form>
                      </div> : <div className="relative flex items-center px-4">
                        <div className="relative h-[3.125rem] w-[3.125rem] sm:h-[3.75rem] sm:w-[3.75rem] flex-none">
                          <img
                            className="absolute inset-0 h-full w-full rounded-full object-cover"
                            src={logo}
                            alt={title}
                          />
                          <div className="absolute inset-0 rounded-full ring-1 ring-inset ring-black/[0.08]" />
                        </div>
                        <dl className="ml-4 flex flex-auto flex-wrap gap-y-1 gap-x-2 overflow-hidden sm:ml-6 sm:grid sm:grid-cols-[auto_1fr_auto_auto] sm:items-center">
                          <div className="col-span-2 mr-2.5 flex-none sm:mr-0">
                            <dt className="sr-only">Company</dt>
                            <dd className="text-xs font-semibold leading-6 text-gray-900">
                              {company}
                            </dd>
                          </div>
                          <div className="col-start-3 row-start-2 -ml-2.5 flex-auto sm:ml-0 sm:pl-6">
                            <dt className="sr-only">Location</dt>
                            <dd className="flex items-center text-xs leading-6 text-gray-600">
                              <svg
                                viewBox="0 0 2 2"
                                aria-hidden="true"
                                className="mr-2 h-0.5 w-0.5 flex-none fill-gray-400 sm:hidden"
                              >
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                              {location}
                            </dd>
                          </div>
                          <div className="col-span-2 col-start-1 w-full flex-none">
                            <dt className="sr-only">Title</dt>
                            <dd className="text-[0.9375rem] font-semibold leading-6 text-gray-900">

                              <span className="absolute -inset-x-4 inset-y-[calc(-1*(theme(spacing.6)+1px))] sm:-inset-x-6 sm:rounded-2xl lg:-inset-x-8" />
                              {title}

                            </dd>
                          </div>
                          <div className="col-start-1 mr-2.5 flex-none">
                            <dt className="sr-only">Type</dt>
                            <dd className="text-xs leading-6 text-gray-600">
                              {types.join(" or ")}
                            </dd>
                          </div>
                          <div className="col-span-3 -ml-2.5 flex-none">
                            <dt className="sr-only">Commission</dt>
                            <dd className="flex items-center text-xs leading-6 text-gray-600">
                              <svg
                                viewBox="0 0 2 2"
                                aria-hidden="true"
                                className="mr-2 h-0.5 w-0.5 flex-none fill-gray-400"
                              >
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                              {commission}
                            </dd>
                          </div>
                        </dl>
                      </div>}


                    </div>
                  </li>
                })}


              </ul>
            </div>
            <div className="text-center mt-2">
              <button onClick={newContract} className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md">New Contract</button>
            </div>
          </div>
        </div>
      </div>
      <div className={tab === "applications" ? "" : "hidden"}>
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="mx-auto max-w-[40rem] text-4xl font-extrabold tracking-tight text-gray-900 sm:text-center sm:text-5xl sm:leading-[3.5rem] lg:max-w-4xl">
            Applications
          </h1>
          <p className="mx-auto mt-4 max-w-[40rem] text-lg text-gray-600 sm:text-center lg:max-w-xl">
            These are the applications for your contracts
          </p>
        </div>
        {/* {JSON.stringify(applications)} */}

        <div className="container m-auto mt-4">
          <div className="flex space-x-2 my-2">
            <div className="flex-1"></div>
            <select ref={actionRef} className="select select-primary w-full max-w-xs">
              <option disabled selected>Action</option>
              <option value="approved">Approve Selected</option>
              <option value="rejected">Reject Selected</option>

            </select>
            <button onClick={doAction} className="btn btn-primary">Go</button>
          </div>
          <table className="table w-full">
            <thead>
              <tr>
                <th>
                  <label>
                    <input onClick={e => [...document.querySelectorAll('[type="checkbox"]')].map(cb => cb.checked = e.target.checked)} type="checkbox" value="" className="checkbox" />
                  </label>
                </th>
                <th>Name</th>
                <th>Job</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application, i) => {
                // {"_id":"6344c6`4e08b52c0cfc152677","profile_id":"6344c64e08b52c0cfc152677","contract_id":"2946eaea-1226-62a4-88bd-b75e65b4ddf7","text":"xx","status":"applied","email":"pg@mailnesia.com","date":"2022-10-13T03:33:32.629Z",,"coords":{"type":"Point","coordinates":[-95.5543544,29.8724486]},"mainLocation":{"address":"C/54 Northwest Freeway, Suite 558,\nHouston, USA 485","phone":"+123 897 5468"}
                let { _id, contract_id, profile, text, status } = application
                let contract = contracts.find(c => c.id === contract_id)
                // { JSON.stringify(contract) }
                return <tr>
                  <th>
                    <label>
                      <input type="checkbox" value={_id} className="checkbox" />
                    </label>
                  </th>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                          <Link to={`/trainers/${profile._id}`}><img src={
                            profile?.avatar
                              ? u(profile?.avatar)
                              : gravatar(profile?.email || "")
                          } alt="Avatar" />
                          </Link>
                        </div>
                      </div>
                      <div>
                        <Link to={`/trainers/${profile._id}`}>
                          <div className="font-bold">{profile.firstname} {profile.lastname}</div>
                        </Link>
                        <div className="text-sm opacity-50">{profile.location}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {contract?.company}
                    <br />
                    <span className="badge badge-ghost badge-sm">{contract?.title}</span>
                  </td>
                  <td>
                    {status}
                  </td>
                  <th>
                    <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                      <div className="collapse-title text-xl font-medium truncate">
                        {text}
                      </div>
                      <div className="collapse-content">
                        <p>{text}</p>
                      </div>
                    </div>

                  </th>
                </tr>
              })}



            </tbody>


          </table>
        </div>
      </div>


    </main>

  </div>


}

export default Brand