function Outlier() {
  return (
    <div className="antialiased bg-body text-body font-body">
      <section>
        <div className="px-8 pt-8 pb-2 bg-gray-600">
          <div className="pb-6 border-b-2 border-gray-500">
            <div className="flex flex-wrap justify-between -mx-4">
              <div className="w-full lg:w-auto px-4 mb-5 lg:mb-0">
                <h4 className="text-2xl font-bold text-white tracking-wide leading-6 mb-1">
                  OUTLIER
                </h4>
                <p className="text-xs leading-5 text-gray-300">
                  Summary of complete dataset
                </p>
              </div>
              <div className="w-full lg:w-auto px-4">
                <div className="flex flex-wrap items-center">
                  <a
                    className="inline-flex h-9 py-1 px-4 mb-2 mr-4 items-center text-center text-sm font-bold text-white hover:bg-gray-700 transition duration-200 rounded-lg"
                    href="/"
                  >
                    DATASET
                  </a>
                  <a
                    className="inline-flex h-9 py-1 px-4 mb-2 mr-4 items-center text-center text-sm font-bold text-white bg-gray-500 hover:bg-gray-400 hover:bg-opacity-40 transition duration-200 rounded-lg"
                    href="/"
                  >
                    AUDIENCES
                  </a>
                  <a
                    className="inline-flex h-9 py-1 px-4 mb-2 mr-4 items-center text-center text-sm font-bold text-white bg-blue-800 hover:bg-blue-700 transition duration-200 rounded-lg"
                    href="/"
                  >
                    CAMPAIGNS
                  </a>
                  <a
                    className="inline-flex h-9 py-1 px-4 mb-2 items-center text-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg"
                    href="/"
                  >
                    ADS
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3">
        <div className="container px-4 mx-auto">
          <div className="mb-6 bg-gray-500 rounded-xl">
            <div className="flex flex-wrap">
              <div className="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10 border-b lg:border-b-0 sm:border-r border-gray-700">
                <div className="py-5 px-6">
                  <h5 className="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide">
                    Rows of Data Total
                  </h5>
                  <div className="flex flex-wrap items-center mb-1 -m-2">
                    <div className="w-auto p-2">
                      <h4 className="text-2xl text-gray-100 font-bold tracking-wide">
                        69.5T
                      </h4>
                    </div>
                    <div className="w-auto p-2">
                      <div className="inline-flex px-2 h-7 items-center justify-center text-purple-500 bg-purple-900 rounded-full">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00002 3.33337V12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.6667 10L8.00002 12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33334 10L8.00001 12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-xs font-medium">59%</span>
                      </div>
                    </div>
                  </div>
                  <p className="text-xs font-semibold text-gray-300">
                    Last week 84,70K
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10 border-b lg:border-b-0 lg:border-r border-gray-700">
                <div className="py-5 px-6">
                  <h5 className="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide">
                    Unique Device IDs
                  </h5>
                  <div className="flex flex-wrap items-center mb-1 -m-2">
                    <div className="w-auto p-2">
                      <h4 className="text-2xl text-gray-100 font-bold tracking-wide">
                        30.4B
                      </h4>
                    </div>
                    <div className="w-auto p-2">
                      <div className="inline-flex px-2 h-7 items-center justify-center text-purple-500 bg-purple-900 rounded-full">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00002 3.33337V12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.6667 10L8.00002 12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33334 10L8.00001 12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-xs font-medium">0,5%</span>
                      </div>
                    </div>
                  </div>
                  <p className="text-xs font-semibold text-gray-300">
                    Last week 242,99K
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10 border-b md:border-b-0 sm:border-r border-gray-700">
                <div className="py-5 px-6">
                  <h5 className="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide">
                    Outlier Match Rate
                  </h5>
                  <div className="flex flex-wrap items-center mb-1 -m-2">
                    <div className="w-auto p-2">
                      <h4 className="text-2xl text-gray-100 font-bold tracking-wide">
                        99.9%
                      </h4>
                    </div>
                    <div className="w-auto p-2">
                      <div className="inline-flex px-2 h-7 items-center justify-center text-green-500 bg-green-900 rounded-full">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3.33337V12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.6667 6.00004L8 3.33337"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33334 6.00004L8.00001 3.33337"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-xs font-medium">1,0%</span>
                      </div>
                    </div>
                  </div>
                  <p className="text-xs font-semibold text-gray-300">
                    Last week 1.18M
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/4 pl-4 md:pl-8 xl:pl-10">
                <div className="py-5 px-6">
                  <h5 className="text-sm font-semibold text-gray-300 mb-6 leading-normal tracking-wide">
                    New Rows Added
                  </h5>
                  <div className="flex flex-wrap items-center mb-1 -m-2">
                    <div className="w-auto p-2">
                      <h4 className="text-2xl text-gray-100 font-bold tracking-wide">
                        30B
                      </h4>
                    </div>
                    <div className="w-auto p-2">
                      <div className="inline-flex px-2 h-7 items-center justify-center text-purple-500 bg-purple-900 rounded-full">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00002 3.33337V12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.6667 10L8.00002 12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33334 10L8.00001 12.6667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-xs font-medium">59%</span>
                      </div>
                    </div>
                  </div>
                  <p className="text-xs font-semibold text-gray-300">
                    Last week 84,70K
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full lg:w-2/3 px-3 mb-6 lg:mb-0">
              <div className="h-full p-6 bg-gray-500 rounded-xl">
                <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                  <div className="w-auto px-4 mb-4">
                    <h4 className="text-xs text-gray-300 mb-1">Match Rate</h4>
                    <h3 className="text-lg text-gray-100 font-bold">
                      <span>99.9%</span>
                      <span className="ml-2 text-xs text-green-500 font-medium">
                        +6%
                      </span>
                    </h3>
                  </div>
                  <div className="w-auto px-4 mb-4">
                    <div className="relative inline-block bg-gray-600 rounded-xl">
                      <select
                        className="relative py-3 pl-2 pr-6 cursor-pointer bg-transparent text-xs text-gray-300 font-semibold appearance-none outline-none"
                        style={{ zIndex: 1 }}
                        name
                        id
                      >
                        <option value>This Year</option>
                        <option value>Last Month</option>
                      </select>
                      <span
                        className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2"
                        style={{ zIndex: 0 }}
                      >
                        <svg
                          width={10}
                          height={7}
                          viewBox="0 0 10 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1.5L5 5.5L9 1.5"
                            stroke="#3D485B"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="chart mt-8" data-type="area" />
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-3">
              <div className="h-full p-6 bg-gray-500 rounded-xl">
                <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                  <div className="w-auto px-4 mb-4">
                    <h4 className="text-xs text-gray-300 mb-1">
                      New Rows Added
                    </h4>
                    <h3 className="text-lg text-gray-100 font-bold">
                      <span>4.535</span>
                      <span className="ml-2 text-xs text-red-500 font-medium">
                        -16%
                      </span>
                    </h3>
                  </div>
                  <div className="w-auto px-4 mb-4">
                    <div className="relative inline-block bg-gray-600 rounded-xl">
                      <select
                        className="relative py-3 pl-2 pr-6 cursor-pointer bg-transparent text-xs text-gray-300 font-semibold appearance-none outline-none"
                        style={{ zIndex: 1 }}
                        name
                        id
                      >
                        <option value>This Week</option>
                        <option value>Last Month</option>
                      </select>
                      <span
                        className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2"
                        style={{ zIndex: 0 }}
                      >
                        <svg
                          width={10}
                          height={7}
                          viewBox="0 0 10 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1.5L5 5.5L9 1.5"
                            stroke="#3D485B"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="chart p-6"
                  data-type="columns"
                  style={{ minHeight: 215 }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0"></div>
            <div className="w-full lg:w-1/2 px-3"></div>
          </div>
        </div>
      </section>
      <section>
        <div className="px-8 pt-8 pb-2 bg-gray-600">
          <div className="pb-6 border-b-2 border-gray-500">
            <div className="flex flex-wrap items-center justify-between -mx-4">
              <div className="w-full md:w-1/2 lg:w-auto px-4 mb-6 md:mb-0">
                <h4 className="text-2xl font-bold text-white tracking-wide leading-6 mb-1">
                  Outlier
                </h4>
                <p className="text-xs leading-5 text-gray-300">
                  © 2022 Centillion. All Rights Reserved.
                </p>
              </div>
              <div className="w-full md:w-1/2 lg:w-auto px-4">
                <div className="md:flex items-center">
                  <div className="flex px-4 h-8 mb-4 md:mb-0 md:mr-4 max-w-sm items-center border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg">
                    <span className="flex-shrink-0">
                      <svg
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.4733 12.5267L10.9999 10.0734C11.96 8.87633 12.4249 7.35692 12.2991 5.82759C12.1733 4.29826 11.4664 2.87525 10.3236 1.85115C9.18085 0.827062 7.68914 0.279732 6.15522 0.321705C4.62129 0.363679 3.16175 0.991766 2.0767 2.07682C0.991644 3.16187 0.363557 4.62142 0.321583 6.15534C0.27961 7.68926 0.82694 9.18097 1.85103 10.3237C2.87512 11.4665 4.29814 12.1735 5.82747 12.2993C7.3568 12.4251 8.87621 11.9601 10.0733 11.0001L12.5266 13.4534C12.5886 13.5159 12.6623 13.5655 12.7436 13.5993C12.8248 13.6332 12.9119 13.6506 12.9999 13.6506C13.0879 13.6506 13.1751 13.6332 13.2563 13.5993C13.3376 13.5655 13.4113 13.5159 13.4733 13.4534C13.5934 13.3291 13.6606 13.163 13.6606 12.9901C13.6606 12.8172 13.5934 12.651 13.4733 12.5267ZM6.33327 11.0001C5.41029 11.0001 4.50804 10.7264 3.74061 10.2136C2.97318 9.7008 2.37504 8.97197 2.02183 8.11925C1.66862 7.26653 1.57621 6.32821 1.75627 5.42297C1.93634 4.51773 2.38079 3.6862 3.03344 3.03356C3.68608 2.38091 4.5176 1.93646 5.42285 1.75639C6.32809 1.57633 7.2664 1.66874 8.11913 2.02195C8.97185 2.37516 9.70068 2.9733 10.2135 3.74073C10.7262 4.50816 10.9999 5.41041 10.9999 6.33339C10.9999 7.57107 10.5083 8.75805 9.6331 9.63322C8.75793 10.5084 7.57095 11.0001 6.33327 11.0001Z"
                          fill="#3D485B"
                        />
                      </svg>
                    </span>
                    <input
                      className="block pl-2 text-sm font-medium text-gray-300 placeholder-gray-400 bg-transparent outline-none"
                      id="pageHeaderInput8-1"
                      type="search"
                      placeholder="Search..."
                    />
                  </div>
                  <button className="inline-flex h-8 py-1 px-4 mr-4 items-center text-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Outlier;
