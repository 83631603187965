import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// setInterval(() => {
//   [...document.querySelectorAll('[style*="z-index"]')].map(iframe => iframe.remove())
// }, 1000)

