// import Nav from "./partial/NavCenterLight"
import Footer from "../partial/Footer";
import Experience from "../Components/Experience"

import { useEffect, useState } from "react";
import { f, u, gravatar } from "../utils";
import { useParams } from "react-router-dom";
import { FaFacebook, FaYoutube, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa"
// import { Link } from "react-router-dom"
// import Switcher from "./partial/Switcher"


function Profile(props) {
  const { nav } = props;//, user, readOnly
  const [profile, setProfile] = useState({});

  let { id } = useParams();

  function reloadProfile() {
    f(`/profiles/${id}`).then(setProfile);
  }

  useEffect(reloadProfile, [id]);


  // partials/navbar/nav-center-light.html

  return (
    <div>
      {nav()}
      <section className="relative lg:pb-24 pb-16">
        <div className="container-fluid">
          <div className="profile-banner relative text-transparent">
            <div className="relative shrink-0">
              <img
                src="assets/images/blog/bg.jpg"
                className="h-80 w-full object-cover"
                id="profile-banner"
                alt="nothing"
              />
              <div className="absolute inset-0 bg-black/70" />
              <label
                className="absolute inset-0 cursor-pointer"
                htmlFor="pro-banner"
              />
            </div>
          </div>
        </div>
        {/*end container*/}
        <div className="container lg:mt-24 mt-16">
          <div className="md:flex">
            <div className="lg:w-1/4 md:w-1/3 md:px-3">
              <div className="relative md:-mt-48 -mt-32">
                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                  <div className="profile-pic text-center mb-5">
                    <div>
                      <div className="relative h-28 w-28 mx-auto">
                        <img
                          src={
                            profile?.avatar
                              ? u(profile?.avatar)
                              : gravatar(profile?.email || "")
                          }
                          className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                          id="profile-image"
                          alt="nothing"
                        />
                        <label
                          className="absolute inset-0 cursor-pointer"
                          htmlFor="pro-img"
                        />
                      </div>
                      <div className="mt-4">
                        <h5 className="text-lg font-semibold">
                          {profile?.firstname || "First"}{" "}
                          {profile?.lastname || "Last"}
                        </h5>
                        <p className="text-slate-400">{profile?.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-100 dark:border-gray-700">
                    {/* <ul
                      className="list-none sidebar-nav mb-0 mt-3"
                      id="navmenu-nav"
                    >
                      <li className="navbar-item account-menu">
                        <button
                          onClick={() => setArea("display")}
                          className="navbar-link text-slate-400 flex items-center py-2 rounded"
                        >
                          <span className="mr-2 text-[18px] mb-0">
                            <i className="uil uil-dashboard" />
                          </span>
                          <h6 className="mb-0 font-semibold">Profile</h6>
                        </button>
                      </li>
                      <li className="navbar-item account-menu">
                        <button
                          onClick={() => setArea("locations")}
                          className="navbar-link text-slate-400 flex items-center py-2 rounded"
                        >
                          <span className="mr-2 text-[18px] mb-0">
                            <i className="uil uil-diary-alt" />
                          </span>
                          <h6 className="mb-0 font-semibold">Locations</h6>
                        </button>
                      </li>
                      <li className="navbar-item account-menu">
                        <button
                          onClick={() => setArea("social")}
                          className="navbar-link text-slate-400 flex items-center py-2 rounded"
                        >
                          <span className="mr-2 text-[18px] mb-0">
                            <i className="uil uil-process" />
                          </span>
                          <h6 className="mb-0 font-semibold">Social Profile</h6>
                        </button>
                      </li>

                    </ul> */}
                  </div>
                </div>
                <div className="flex justify-center space-x-3 my-4 text-blue-500">
                  {profile.facebook && <a href={profile.facebook} rel="noreferrer" target="_blank"><FaFacebook size={32} /></a>}
                  {profile.twitter && <a href={profile.twitter} rel="noreferrer" target="_blank"><FaTwitter size={32} /></a>}
                  {profile.instagram && <a href={profile.instagram} rel="noreferrer" target="_blank"><FaInstagram size={32} /></a>}
                  {profile.linkedin && <a href={profile.linkedin} rel="noreferrer" target="_blank"><FaLinkedin size={32} /></a>}
                  {profile.youtube && <a href={profile.youtube} rel="noreferrer" target="_blank"><FaYoutube size={32} /></a>}

                </div>

              </div>
            </div>

            {/* display */}
            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
              <div className="pb-6 border-b border-gray-100 dark:border-gray-700">
                <h5 className="text-xl font-semibold">{profile?.firstname || "First"} {profile?.lastname || "Last"}</h5>
                <p className="text-slate-400 mt-3">{profile?.description || "Description goes here"}</p>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px] pt-6">
                <div>
                  <h5 className="text-xl font-semibold">Personal Details :</h5>
                  <div className="mt-6">
                    <div className="flex items-center">
                      <i data-feather="mail" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Email :</h6>
                        <div className="text-slate-400">{profile?.email}</div>
                      </div>
                    </div>
                    <div className="flex items-center mt-3">
                      <i data-feather="bookmark" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Tags :</h6>
                        <div className="text-slate-400">{(profile?.tags || []).join(", ")}</div>

                      </div>
                    </div>
                    <div className="flex items-center mt-3">
                      <i data-feather="italic" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Language :</h6>
                        <div className="text-slate-400">English</div>
                      </div>
                    </div>
                    {profile?.website && <div className="flex items-center mt-3">
                      <i data-feather="globe" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Website :</h6>
                        <div href={profile.website} target="_blank" rel="noreferrer" className="text-slate-400">{profile.website.replace(/https?:\/\//i, '')}</div>
                      </div>
                    </div>}
                    <div className="flex items-center mt-3">
                      <i data-feather="gift" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Birthday :</h6>
                        <p className="text-slate-400 mb-0">2nd March, 1996</p>
                      </div>
                    </div>
                    <div className="flex items-center mt-3">
                      <i data-feather="map-pin" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Location :</h6>
                        <div className="text-slate-400">Beijing, China</div>
                      </div>
                    </div>
                    {profile?.phone && <div className="flex items-center mt-3">
                      <i data-feather="phone" className="fea icon-ex-md text-slate-400 mr-3" />
                      <div className="flex-1">
                        <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Phone :</h6>
                        <div className="text-slate-400">{profile.phone}</div>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* <pre>{JSON.stringify(profile, null, 2)}</pre> */}
                <Experience experience={profile.experience || []} />

              </div>
              <h5 className="text-xl font-semibold mt-[30px]">Portfolio :</h5>
              {profile.portfolio && <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-6 gap-[30px]">
                {profile.portfolio.map((path, i) => <div key={i} className="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <img src={u(path)} className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500" alt="nothing" />
                  <div className="absolute inset-2 group-hover:bg-white/90 dark:group-hover:bg-slate-900/90 transition duration-500 z-0 rounded-md" />
                  <div className="content transition-all duration-500">

                    {/* <div className="title absolute z-10 hidden group-hover:block bottom-6 left-6">
            <a href="portfolio-detail-three.html" className="h6 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Mockup Collection</a>
            <p className="text-slate-400 mb-0">Abstract</p>
          </div> */}
                  </div>
                </div>)}
              </div>}
            </div>
            {/* display */}





          </div>
        </div>
      </section>

      <Footer />
    </div>
  );

}

export default Profile;
