import Nav from "./partial/NavCenterLight"
import Footer from "./partial/Footer"
// import Switcher from "./partial/Switcher"
// import GMap from "./GMap"
import Search from "./Search"
import Leaflet from "./Leaflet"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { f } from "./utils";

function Map(props) {
  let [listings, setListings] = useState()

  // partials/navbar/nav-center-light.html
  const {"*": splat} = useParams ()
  let [lat, lng, tag, dist] = splat.split('/')
  tag = tag || "all"
  dist = dist || "25"

  let navigate = useNavigate()

  // let [center, setCenter] = useState(false)
  useEffect(() => {
    if(!lat && !lng){
      navigator.geolocation.getCurrentPosition(c => {
        let {latitude, longitude} = c.coords
        if(latitude < 11){
          latitude = 29.749907
          longitude = -95.358421
        }
        navigate(`/map/${latitude}/${longitude}/${tag}/${dist}`)
      })
    }
    // eslint-disable-next-line
  }, [lat, lng])

  useEffect(() => {
    f(`/listings/${lat}/${lng}/${tag}/${dist}`).then(setListings)
  }, [lat, lng, tag, dist])

  return <div>
    <Nav />
    <div className="w-full bg-yellow-600 my-20 grid grid-cols-1 lg:grid-cols-2">
      <Search listings={listings}/>

      {lat && <Leaflet center={{lat, lng}} key={lat} {...{lat, lng, tag, dist}}/>}
      {/* {center && <GMap center={center}/>} */}
    </div>


    <Footer />
    {/* <Switcher /> */}

  </div>







  // <a href="/" onclick="topFunction()" id="back-to-top" class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 right-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"><i class="uil uil-arrow-up"></i></a>


}

export default Map