function Footer() {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-4 md:col-span-12">
                  <a href="/" className="text-[22px] focus:outline-none ">
                    <svg
                      className="w-72"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 159 33"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="M0 0H159V33H0z" />
                        <g fill="#fff" fill-rule="nonzero">
                          <path
                            d="M12.15 0C5.44 0 0 5.44 0 12.15S5.44 24.3 12.15 24.3 24.3 18.86 24.3 12.15C24.292 5.443 18.857.008 12.15 0zM22.5 12.15c.002 1.836-.489 3.64-1.42 5.222L14.023 8.55h7.83c.429 1.152.648 2.371.647 3.6zM12.15 1.8c3.603.002 6.945 1.877 8.826 4.95H3.324c1.88-3.073 5.223-4.948 8.826-4.95zM2.447 8.55h7.83L3.22 17.373c-1.57-2.676-1.854-5.915-.773-8.823zm1.861 10.344l6.942-8.678v12.242c-2.69-.235-5.183-1.514-6.942-3.564zm8.742 3.564V10.216l6.942 8.677c-1.759 2.05-4.25 3.33-6.942 3.565z"
                            transform="translate(8 3)"
                          />
                          <g>
                            <path
                              d="M20.79 6.12c-1.318 0-2.543.68-3.24 1.8h1.666c1.49 0 2.7 1.209 2.7 2.7v7.74h2.7V9.945c0-2.112-1.713-3.825-3.825-3.825zM14.4 0H17.1V18.36H14.4zM0 2.16L5.4 2.16 5.4 18.36 8.1 18.36 8.1 2.16 12.754 2.16 13.5.36.746.36zM86.372 14.22V8.154c-1.618.571-2.7 2.1-2.7 3.816v2.7c0 1.784 1.166 3.357 2.873 3.875 1.706.518 3.55-.142 4.541-1.625h-2.014c-1.491 0-2.7-1.209-2.7-2.7z"
                              transform="translate(8 3) translate(34.2 2.34)"
                            />
                            <path
                              d="M91.536 6.351c-1.745-.617-3.686.03-4.714 1.569h2.014c1.492 0 2.7 1.209 2.7 2.7v7.74h2.7V0h-2.7v6.351zM38.53 6.48H41.230000000000004V18.36H38.53zM61.643 14.22V0h-2.7v14.67c-.001 1.784 1.166 3.358 2.872 3.875 1.707.518 3.552-.142 4.542-1.625h-2.014c-1.492 0-2.7-1.209-2.7-2.7z"
                              transform="translate(8 3) translate(34.2 2.34)"
                            />
                            <path
                              d="M65.457 6.12c-1.352 0-2.614.675-3.364 1.8h2.014c1.491 0 2.7 1.209 2.7 2.7v6.066c1.618-.571 2.7-2.1 2.7-3.816v-2.7c0-2.237-1.813-4.05-4.05-4.05zM51.743 6.12c-1.417-.001-2.751.666-3.6 1.8h2.7c.716 0 1.402.284 1.909.79.506.507.79 1.194.79 1.91v.9h-3.6c-1.747-.002-3.243 1.252-3.548 2.973-.304 1.72.671 3.411 2.313 4.01 1.642.598 3.476-.07 4.35-1.583h-1.314c-.947.002-1.826-.493-2.314-1.305-.489-.81-.515-1.819-.07-2.655h4.184v5.4h2.7v-7.74c0-2.485-2.015-4.5-4.5-4.5zM77.822 6.12c-1.352 0-2.615.675-3.365 1.8h2.015c1.491 0 2.7 1.209 2.7 2.7v6.066c1.618-.571 2.7-2.1 2.7-3.816v-2.7c0-2.237-1.813-4.05-4.05-4.05zM74.007 14.22V8.154c-1.617.571-2.7 2.1-2.7 3.816v2.7c0 1.784 1.166 3.357 2.873 3.875 1.707.518 3.551-.142 4.542-1.625h-2.015c-1.49 0-2.7-1.209-2.7-2.7zM33.13 6.12c-1.285 0-2.472.687-3.114 1.8h1.764c1.243 0 2.25 1.007 2.25 2.25v.72h-4.456l-.008-2.85c-1.588.409-2.699 1.84-2.7 3.48v2.25c0 2.089 1.31 3.953 3.276 4.659 1.966.706 4.163.102 5.492-1.509H33.63c-2.237 0-4.05-1.813-4.05-4.05l-.001-.54h7.152V9.72c0-1.988-1.612-3.6-3.6-3.6zM46.89 8.933c0-1.355-.965-2.517-2.297-2.765-1.331-.249-2.65.488-3.138 1.752h1.16c.87 0 1.575.705 1.575 1.575v1.238l2.7-1.8zM105.148 6.48L101.874 14.383 98.601 6.48 95.678 6.48 100.413 17.91 97.543 24.84 99.491 24.84 107.096 6.48z"
                              transform="translate(8 3) translate(34.2 2.34)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>

                  {/*end icon*/}
                </div>
                {/*end col*/}
                <div className="lg:col-span-2 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Company
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    <li>
                      <a
                        href="page-aboutus.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> About us
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="page-services.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Services
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="page-team.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Team
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="page-pricing.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Pricing
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="portfolio-creative-four.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Project
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="blog.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Blog
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="auth-login.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Login
                      </a>
                    </li>
                  </ul>
                </div>
                {/*end col*/}
                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Usefull Links
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    <li>
                      <a
                        href="page-terms.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Terms of
                        Services
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="page-privacy.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Privacy
                        Policy
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="documentation.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" />{" "}
                        Documentation
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="changelog.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Changelog
                      </a>
                    </li>
                    <li className="mt-[10px]">
                      <a
                        href="widget.html"
                        className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                      >
                        <i className="uil uil-angle-right-b me-1" /> Widget
                      </a>
                    </li>
                  </ul>
                </div>
                {/*end col*/}
                <div className="lg:col-span-3 md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Newsletter
                  </h5>
                  <p className="mt-6">
                    Sign up and receive the latest tips via email.
                  </p>
                  <form>
                    <div className="grid grid-cols-1">
                      <div className="foot-subscribe my-3">
                        <label className="form-label">
                          Write your email{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="form-icon relative mt-2">
                          <i
                            data-feather="mail"
                            className="w-4 h-4 absolute top-3 left-4"
                          />
                          <input
                            type="email"
                            className="form-input bg-gray-800 border border-gray-800 text-gray-100 pl-12 focus:shadow-none"
                            placeholder="Email"
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        id="submitsubscribe"
                        name="send"
                        className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
                {/*end col*/}
              </div>
              {/*end grid*/}
            </div>
            {/*end col*/}
          </div>
        </div>
        {/*end grid*/}
      </div>
      {/*end container*/}
    </footer>
  );
}

export default Footer;
