import { useEffect, useRef, useState } from "react";
import { f, p } from "./utils";

function Contracts(props) {
  const { nav } = props;
  let [params] = useState({}) // , setParams
  let [contracts, setContracts] = useState([])
  let [contract, setContract] = useState()
  let [statuses, setStatuses] = useState({})

  useEffect(() => {
    p('/contracts/search').then(setContracts)
    f('/contracts/statuses').then(setStatuses)
  }, [params])

  function apply() {
    let text = fitRef.current.value
    if(text){
      p('/contracts/apply', {text, contract_id: contract.id})
      applyRef.current.checked = false
      let newStatuses = { ...statuses }
      newStatuses[contract.id] = "applied"
      setStatuses(newStatuses)
    } else {
      alert("Please enter some text")
    }
  }


  let applyRef = useRef()
  let fitRef = useRef()


  return <div>
    {nav()}



    {/* Put this part before </body> tag */}
    <input type="checkbox" ref={applyRef} className="modal-toggle" />
    <div className="modal">
      <div className="modal-box w-96">
        <h3 className="font-bold text-lg">Apply for {contract?.company} / {contract?.title}</h3>
        <p className="py-4">Tell the brand why you're a good fit for this contract</p>
        <textarea ref={fitRef} rows={4} className="textarea w-full" placeholder=""></textarea>

        <div className="modal-action">
          <button onClick={apply} className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md">Apply</button>
          <button onClick={() => applyRef.current.checked = false} className="btn">Cancel</button>
        </div>
      </div>
    </div>

    <main className="relative pt-10 pb-16 sm:pb-10 sm:pt-20 lg:pb-28">
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="mx-auto max-w-[40rem] text-4xl font-extrabold tracking-tight text-gray-900 sm:text-center sm:text-5xl sm:leading-[3.5rem] lg:max-w-4xl">
          Active Contracts
        </h1>
        <p className="mx-auto mt-4 max-w-[40rem] text-lg text-gray-600 sm:text-center lg:max-w-xl">
          These are the contracts that match your search.
        </p>
      </div>
      {/* {JSON.stringify(statuses)} */}
      <div className="mt-4">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-[37rem] lg:max-w-[50rem]">
            <ul className="divide-y divide-gray-100">
              {contracts.map((contract, i) => {
                let { logo, company, location, title, types, commission, id } = contract
                let status = statuses[id]
                return <li key={i}>
                  <div className="group relative py-6 sm:rounded-2xl hover:bg-gray-100">
                    <div className="flex float-right">
                      {/* <button className="z-10 p-1 bg-gray-100 hover:bg-gray-200 rounded-full mx-1" onClick={() => remove(i)} title="delete">
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                    </button>
                    {editing !== i && <button className="p-1 bg-gray-100 hover:bg-gray-200 rounded-full  mx-1" onClick={() => setEditing(i)} title="edit">
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>} */}
                      {status ? <div className="text-center"><button className="btn btn-info">{status}</button></div>
                    : <div className="text-center"><button onClick={() => {
                        applyRef.current.checked = true
                        setContract(contract)
                      }} className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md">Apply</button></div>}
                    </div>

                    {/* <div className="absolute -inset-x-4 -inset-y-px bg-gray-50 opacity-0 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl lg:-inset-x-8" >

                    </div> */}

                    <div className="relative flex items-center px-4">
                      <div className="relative h-[3.125rem] w-[3.125rem] sm:h-[3.75rem] sm:w-[3.75rem] flex-none">
                        <img
                          className="absolute inset-0 h-full w-full rounded-full object-cover"
                          src={logo}
                          alt={title}
                        />
                        <div className="absolute inset-0 rounded-full ring-1 ring-inset ring-black/[0.08]" />
                      </div>
                      <dl className="ml-4 flex flex-auto flex-wrap gap-y-1 gap-x-2 overflow-hidden sm:ml-6 sm:grid sm:grid-cols-[auto_1fr_auto_auto] sm:items-center">
                        <div className="col-span-2 mr-2.5 flex-none sm:mr-0">
                          <dt className="sr-only">Company</dt>
                          <dd className="text-xs font-semibold leading-6 text-gray-900">
                            {company}
                          </dd>
                        </div>
                        <div className="col-start-3 row-start-2 -ml-2.5 flex-auto sm:ml-0 sm:pl-6">
                          <dt className="sr-only">Location</dt>
                          <dd className="flex items-center text-xs leading-6 text-gray-600">
                            <svg
                              viewBox="0 0 2 2"
                              aria-hidden="true"
                              className="mr-2 h-0.5 w-0.5 flex-none fill-gray-400 sm:hidden"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            {location}
                          </dd>
                        </div>
                        <div className="col-span-2 col-start-1 w-full flex-none">
                          <dt className="sr-only">Title</dt>
                          <dd className="text-[0.9375rem] font-semibold leading-6 text-gray-900">

                            <span className="absolute -inset-x-4 inset-y-[calc(-1*(theme(spacing.6)+1px))] sm:-inset-x-6 sm:rounded-2xl lg:-inset-x-8" />
                            {title}

                          </dd>
                        </div>
                        <div className="col-start-1 mr-2.5 flex-none">
                          <dt className="sr-only">Type</dt>
                          <dd className="text-xs leading-6 text-gray-600">
                            {types.join(" or ")}
                          </dd>
                        </div>
                        <div className="col-span-3 -ml-2.5 flex-none">
                          <dt className="sr-only">Commission</dt>
                          <dd className="flex items-center text-xs leading-6 text-gray-600">
                            <svg
                              viewBox="0 0 2 2"
                              aria-hidden="true"
                              className="mr-2 h-0.5 w-0.5 flex-none fill-gray-400"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            {commission}
                          </dd>
                        </div>
                      </dl>
                    </div>


                  </div>
                </li>
              })}


            </ul>
          </div>

        </div>
      </div>
    </main>

  </div>


}

export default Contracts