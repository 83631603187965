import { Link } from "react-router-dom"

function ForgotPassword() {
  return <section className="md:h-screen py-36 flex items-center bg-[url('assets/images/cta.jpg')] bg-no-repeat bg-center">
    <div className="container">
      <div className="flex justify-center">
        <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
          <Link to="/"><img src="assets/images/logo-icon-64.png" className="mx-auto" alt="home" /></Link>
          <h5 className="my-6 text-xl font-semibold">Reset Your Password</h5>
          <div className="grid grid-cols-1">
            <p className="text-slate-400 mb-6">Please enter your email address. You will receive a link to create a new password via email.</p>
            <form className="text-left">
              <div className="grid grid-cols-1">
                <div className="mb-4">
                  <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                  <input id="LoginEmail" type="email" className="form-input mt-3" placeholder="name@example.com" />
                  <div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></div>
                <div className="mb-4">
                  <input type="submit" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" defaultValue="Send" />
                </div>
                <div className="text-center">
                  <span className="text-slate-400 me-2">Remember your password ? </span><Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

}

export default ForgotPassword