import { useState } from "react";

function getLogo(company) {
  switch (true) {
    case !company:
      return "/assets/images/client/circle-logo.png";
    case !!company.match(/facebook/i):
      return "/assets/images/client/facebook.svg";
    case !!company.match(/google/i):
      return "/assets/images/client/google.png";
    case !!company.match(/lenovo/i):
      return "/assets/images/client/lenovo.png";
    case !!company.match(/linkedin/i):
      return "/assets/images/client/linkedin.png";
    case !!company.match(/paypal/i):
      return "/assets/images/client/paypal.png";
    case !!company.match(/shopify/i):
      return "/assets/images/client/shopify.png";
    case !!company.match(/skype/i):
      return "/assets/images/client/skype.png";
    case !!company.match(/snapchat/i):
      return "/assets/images/client/snapchat.png";
    case !!company.match(/spotify/i):
      return "/assets/images/client/spotify.png";
    case !!company.match(/telegram/i):
      return "/assets/images/client/telegram.png";
    case !!company.match(/whatsapp/i):
      return "/assets/images/client/whatsapp.png";
    default:
      return "/assets/images/client/circle-logo.png";
  }
}

function Experience(props) {
  let { update, experience } = props;
  let [editing, setEditing] = useState(-1);

  // let [experience, setExperience] = useState(experience)

  function addExperience() {
    let l = experience.length;
    update({ experience: [...experience, {}] });
    setEditing(l);
  }

  function removeExperience(i) {
    update({
      experience: [...experience.slice(0, i), ...experience.slice(i + 1)],
    });
    if (editing > i) setEditing(editing - 1);
  }

  function save(i) {
    let q = [...document.querySelectorAll(`#experience [name]`)].reduce(
      (acc, el) => {
        let key = el.getAttribute("name");
        let value = el.value || el.innerText;
        acc[key] = value;
        return acc;
      },
      {}
    );
    let newExperience = [...experience];
    newExperience[editing] = q;
    update({ experience: newExperience });
    // alert(JSON.stringify(q))
    setEditing(-1);
  }

  return (
    <div id="experience">
      {experience.map((ex, i) =>
        editing === i ? (
          <div
            key={i}
            className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-5"
          >
            <img
              src={getLogo(ex.company)}
              className="h-16 w-16 p-4 bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md"
              alt="nothing"
            />
            <div className="flex-1 content ml-4">
              <h4 className="text-lg text-medium">
                <input
                  type="text"
                  name="title"
                  placeholder="title"
                  defaultValue={ex.title}
                />{" "}
                <button
                  onClick={() => save(i)}
                  className="px-2 py-1 text-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2"
                >
                  save
                </button>
              </h4>
              <p className="text-slate-400 mb-0">
                <input
                  type="text"
                  name="length"
                  placeholder="length"
                  defaultValue={ex.length}
                />{" "}
                Experience
              </p>
              <p className="text-slate-400 mb-0">
                <span className="text-indigo-600">
                  <input
                    type="text"
                    name="company"
                    placeholder="company"
                    defaultValue={ex.company}
                  />
                </span>{" "}
                @
                <input
                  type="text"
                  name="location"
                  placeholder="location"
                  defaultValue={ex.location}
                />
              </p>
            </div>
          </div>
        ) : (
          <div key={i} className="flex p-4 rounded-md bg-[#FFF5E5] mt-5">
            <div className="h-20 w-20 p-5 bg-[#FAE4A7] rounded-lg">
              <svg
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4C12.94 4 4 13 4 24C4 35 13 44 24 44C35 44 44 35 44 24C44 13 35 4 24 4ZM24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C32.84 8 40 15.16 40 24C40 32.84 32.84 40 24 40ZM16 28L14 16L20 20L24 14L28 20L34 16L32 28H16ZM17.12 32C16.44 32 16 31.56 16 30.88V30H32V30.88C32 31.56 31.56 32 30.88 32H17.12Z"
                  fill="#FC9700"
                />
              </svg>
            </div>
            <div className="flex-1 content ml-4">
              <h4 className="text-lg text-medium">
                <h3 className="text-lg font-semibold">{ex.title}</h3>{" "}
              </h4>
              <p className="text-slate-400 mb-0 text-sm">
                {ex.length} Years{" "}
                <span className="text-indigo-600">{ex.company}</span>
              </p>
              <p className="text-slate-400 mb-0"> @{ex.location}</p>
              {update && (
                <button
                  onClick={() => setEditing(i)}
                  className="px-2 py-1 text-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2"
                >
                  edit
                </button>
              )}{" "}
              {update && (
                <button
                  onClick={() => removeExperience(i)}
                  className="px-2 py-1 text-sm bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2"
                >
                  delete
                </button>
              )}
            </div>
          </div>
        )
      )}
      {update && (
        <button
          onClick={() => addExperience()}
          className="px-2 py-1 text-sm bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md mt-2"
        >
          + Add Experience
        </button>
      )}
    </div>
  );
}

export default Experience;
