import Nav from "./partial/NavCenterLight";
import Footer from "./partial/Footer";
// import { useRef } from "react"
// import { useNavigate } from "react-router-dom";
import heroimg from "../src/assets/images/Hero-img.png";
import workout from "../src/assets/images/workout.png";
import w1 from "../src/assets/images/w1.png";
import w2 from "../src/assets/images/w2.png";
import w3 from "../src/assets/images/w3.png";
import w4 from "../src/assets/images/w4.png";
import anywhere from "../src/assets/images/anywhere.png";
import experts from "../src/assets/images/experts.png";
// import Switcher from "./partial/Switcher";

// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng
// } from 'react-places-autocomplete';

function Home(props) {
  // const navigate = useNavigate();

  // partials/navbar/nav-center-light.html
  // let inputRef = useRef()
  // function findTrainer() {
    // alert(inputRef.current.value)
    // geocodeByAddress(inputRef.current.value)
    //   .then(results => {
    //     console.log(results)
    //     getLatLng(results[0]).then(coords => {
    //       console.log(coords)
    //     })
    //   })
  //   navigate("/map");
  // }

  return (
    <div>
      <Nav />
      <section>
        <div className="flex flex-col justify-center md:mt-32 mt-48">
          <h1 className="md:text-7xl text-5xl font-bold text-center m-auto">
            One membership for fitness,
            <br className="md:block hidden" /> wellness and beauty
          </h1>
          <img alt="missing"  className="w-full -mt-7" src={heroimg} />
          <div className="text-center -md:mt-16">
            <button
              type="submit"
              id="submitsubscribe"
              name="send"
              className="btn inline-block bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md mt-10"
            >
              Get Started
            </button>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-52">
          <h2 className="text-4xl font-semibold">
            Everyone is welcome at TheraBody Fitness. Now all you need is to
            subscribe and access thousands of video and audio workouts —
            everything from HIIT to Yoga.
          </h2>

          <div className="mt-24 grid md:grid-cols-3 grid-cols-2 gap-10">
            <div>
              <h2 className="text-4xl font-semibold">
                10$
                <span className="font-medium text-lg text-gray-300">
                  /month
                </span>
              </h2>
              <p className="font-semibold text-gray-300">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
              <button
                type="submit"
                id="submitsubscribe"
                name="send"
                className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md mt-10"
              >
                Get Started
              </button>
            </div>
            <div>
              <h2 className="text-4xl font-semibold">
                50$
                <span className="font-medium text-lg text-gray-300">
                  /month
                </span>
              </h2>
              <p className="font-semibold text-gray-300">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
              <button
                type="submit"
                id="submitsubscribe"
                name="send"
                className="btn bg-yellow-500 border-yellow-500 hover:bg-yellow-700 text-black rounded-md mt-10"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <img alt="missing"  className="w-4/5 m-auto" src={workout} />
        </div>
      </section>
      <section>
        <div className="container mt-52">
          <h2 className="text-5xl font-semibold">
            Workouts and meditations
            <br /> that are just your type.
          </h2>
          <p className="font-medium text-gray-300 text-3xl mt-5">
            From HIIT to Yoga, there’s something for everyone. Choose from 11
            different workout types, meditations with specific themes like calm
            and focus, and audio‑guided walks and runs — each with the perfect
            music playlist.
          </p>
          <div className="grid md:grid-cols-4 grid-cols-2 gap-6 my-20">
            <div>
              <img alt="missing"  className="w-full" src={w1} />
            </div>
            <div>
              <img alt="missing"  className="w-full" src={w2} />
            </div>
            <div>
              <img alt="missing"  className="w-full" src={w3} />
            </div>
            <div>
              <img alt="missing"  className="w-full" src={w4} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-52">
          <h2 className="text-5xl font-semibold">
            Everyday Up to date
            <br /> Fitness freak library for you
          </h2>
          <p className="font-medium text-gray-300 text-3xl mt-5">
            Fitness+ has the largest library of 4K Ultra HD fitness and wellness
            content in the world. And new workouts and meditations are added
            every week, from 5 to 45 minutes. It’s easy to stay motivated when
            there’s always something fresh.
          </p>
        </div>
        <div className="flex overflow-x-auto whitespace-nowrap  gap-6 my-20">
          <div>
            <div className="mb-4 h-44 w-72 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1518611012118-696072aa579a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
          <div>
            <div className="mb-4 h-44 w-72 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
          <div>
            <div className="mb-4 h-44 w-72 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2338&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
          <div>
            <div className="mb-4 h-44 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1623200216581-969d9479cf7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
          <div>
            <div className="mb-4 h-44 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1581009146145-b5ef050c2e1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
          <div>
            <div className="mb-4 h-44 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1599058918144-1ffabb6ab9a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2338&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
          <div>
            <div className="mb-4 h-44 w-72 w-72 bg-gray-300 rounded-lg overflow-hidden">
              <img alt="missing"
                className="w-full object-cover"
                src="https://images.unsplash.com/photo-1623199648374-a4ff4e14e719?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2020&q=80"
              />
            </div>
            <h3 className="font-semibold text-xl">HIIT with Anja</h3>
            <h3 className="font-medium text-xl text-gray-300">
              Latin Grooves | 20 minutes
            </h3>
          </div>
        </div>
      </section>

      <section>
        <div>
          <img
            className="w-full scale-150 md:scale-100 mt-40"
            src={anywhere}
            alt="anywhere"
          />
        </div>
      </section>

      <section>
        <div className="container mt-52">
          <h2 className="text-5xl font-semibold">
            Be fit with our
            <br /> Motivators & Trainers
          </h2>
          <p className="font-medium text-gray-300 text-3xl mt-5">
            ThereBody trainers are welcoming individuals, chosen as much for
            their incredible expertise as for their friendly approach. They
            don’t just create their own routines, they also appear in each
            other’s videos and work with experts to create motivating playlists.
            It’s a true collaboration that lifts the whole team up.
          </p>
        </div>
        <div className=" my-20">
          <div>
            <img alt="missing"  className="w-full" src={experts} />
          </div>
        </div>
      </section>

      <Footer />
      {/* <Switcher /> */}
    </div>
  );

  // <a href="/" onclick="topFunction()" id="back-to-top" class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 right-5 h-9 w-9 text-center bg-indigo-600 text-white leading-9"><i class="uil uil-arrow-up"></i></a>
}

export default Home;
