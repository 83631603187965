import React from 'react'
import { WithContext as ReactTags } from 'react-tag-input'

const suggestions = ["Trainer", "Cardio", "Weights", "Bikes", "Running"].map((text, id) => {
  return { id: text, text }
})

const KeyCodes = {
  comma: 188,
  enter: 13,
  tab:9
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Tags = (props) => {
  const {update, init} = props
  const [tags, setTags2] = React.useState(init.map((text, id) => {
    return { id: text, text }
  }))

  function setTags(t){
    setTags2(t)
    update(t.map(t => t.text))
  }

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  return <ReactTags
          tags={tags}
          suggestions={suggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          autocomplete
        />

};

export default Tags